import Styles from "./styles";
import html2canvas from 'html2canvas';
import { callNative } from "utils/etc";
import { useLanguage, useModals } from "hooks";

// img
import IconShare from "resources/image/icon/icon_share.svg";
import IconDownload from "resources/image/icon/icon_download.svg";
import IconEdit from "resources/image/icon/icon_edit.svg";
import IconTrash from "resources/image/icon/icon_trash.svg";
import { api } from "service";

function MoreBottomSheetModal({ modalClose, modalOption }) {
  const modal = useModals();
  const optionData = modalOption.data || {};
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const handleCapture = async () => {
    if (optionData.captureRef?.current) {
      // html2canvas로 특정 영역 캡처
      const canvas = await html2canvas(optionData.captureRef.current, {
        useCORS: true, // CORS를 사용하도록 설정
        allowTaint: false, // 기본값은 false, CORS 이미지가 필요함
      });
      // 이미지 URL 생성 (Blob 데이터를 활용할 수도 있음)
      const imgData = canvas.toDataURL('image/png',0.75);

      let file = base64ToFile(imgData,"stamfit_" + new Date().getTime() + ".png");
      let formData = new FormData();
      formData.append("file",file);
      let response = await api.policy.imageProcess(formData);
      callNative("DOWNLOAD",response.data?.image,"");

      // 이미지를 다운로드할 수 있도록 링크 생성
      // const link = document.createElement('a');
      // link.href = imgData;
      // link.download = 'savedImage.png';
      // link.click();
    }
  };

  function base64ToFile(base64Data, fileName) {
    // Base64 데이터에서 헤더 제거
    const byteString = atob(base64Data.split(',')[1]);
  
    // ArrayBuffer 생성
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    // byteString을 Uint8Array로 변환
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    // Blob 생성
    const blob = new Blob([uint8Array], { type: 'image/png' });
  
    // File 객체 생성 (File 생성자 사용)
    const file = new File([blob], fileName, { type: 'image/png' });
  
    return file;
  }

  const onEdit = () => {
    switch (parseInt(optionData.data?.category_info_id)) {
      case 1:
        return callNative("moveToPage", "EyeBody", {
          id: optionData.data?.id,
          weight: optionData.data?.weight,
          path: optionData.data?.image,
        });
      case 2:
        return callNative("moveToPage", "Meal", {
          id: optionData.data?.id,
          path: optionData.data?.image,
        });
      case 3:
        return callNative("moveToPage", "Exercise", {
          id: optionData.data?.id,
          path: optionData.data?.image,
        });
      case 4:
        return callNative("moveToPage", "Etc", {
          id: optionData.data?.id,
          path: optionData.data?.image,
        });
    }
  };

  const list = [
    {
      icon: IconShare,
      text: languageQuarter ? "공유하기" : "Share",
      onclick: () => {
        callNative("SHARE", "", {}, 'https://stamfit.com/app/download' );
        // modal.setToastModal((e) => {
        //   e.show = true;
        //   e.text = languageQuarter
        //     ? "공유가 완료되었습니다."
        //     : "The sharing has been completed."

        //   return { ...e };
        // });
      },
    },
    {
      icon: IconDownload,
      text: languageQuarter ? "저장하기" : "Save",
      onclick: () => {
        handleCapture();
        modal.setToastModal((e) => {
          e.show = true;
          e.text = languageQuarter
            ? "저장이 완료되었습니다."
            : "The save has been completed."

          return { ...e };
        });
      },
    },
    {
      icon: IconEdit,
      text: languageQuarter ? "수정하기" : "Edit",
      onclick: onEdit,
    },
    {
      icon: IconTrash,
      text: languageQuarter ? "삭제하기" : "Delete",
      onclick: () => {
        modal.setFeedDeleteModal((e) => {
          e.show = true;
          e.id = optionData.data?.id;

          return { ...e };
        });
      },
    },
  ];

  return (
    <Styles.Container>
      <div className="modalInner">
        <button
          type="button"
          className="closeButton"
          onClick={modalClose}
        ></button>

        <ul className="optionListWrap inner">
          {list?.map((item, index) => {
            return (
              <li
                className="optionList"
                key={"optionList" + index}
                onClick={() => {
                  item.onclick();
                  modalClose();
                }}
              >
                <div className="icon">
                  <img src={item.icon} alt="optionIcon" />
                </div>

                <p className="text">{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { MoreBottomSheetModal };

export default MoreBottomSheetModal;
