import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import FeedDetailPage from "./FeedDetailPage";
import IntroPage from "./IntroPage";
import LoginPage from "./LoginPage";
import AskPage from "./AskPage";
import SignupPage from "./SignupPage";
import FindOutPage from "./FindOutPage";
import SignupCompletePage from "./SignupCompletePage";
import MyPage from "./MyPage";
import PaymentDetailPage from "./PaymentDetailPage";
import TermsPage from "./TermsPage";
import AccountSettingPage from "./AccountSettingPage";
import ComparisonPage from "./ComparisonPage";
import ComparisonSelectPage from "./ComparisonSelectPage";
import PayCompletePage from "./PayCompletePage";
import AppDownload from "./AppDownload";
import NativePage from "./NativePage";

export default {
  ModalPage,
  MainPage,
  FeedDetailPage,
  IntroPage,
  LoginPage,
  AskPage,
  SignupPage,
  FindOutPage,
  SignupCompletePage,
  MyPage,
  PaymentDetailPage,
  TermsPage,
  AccountSettingPage,
  ComparisonPage,
  ComparisonSelectPage,
  PayCompletePage,
  AppDownload,
  NativePage,
};

export {
  ModalPage,
  MainPage,
  FeedDetailPage,
  IntroPage,
  LoginPage,
  AskPage,
  SignupPage,
  FindOutPage,
  SignupCompletePage,
  MyPage,
  PaymentDetailPage,
  TermsPage,
  AccountSettingPage,
  ComparisonPage,
  ComparisonSelectPage,
  PayCompletePage,
  AppDownload,
  NativePage,
};
