import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DOMAIN } from "service/connector";

function NativePage() {

  const [serchParams] = useSearchParams();

  useEffect(()=>{
    setTimeout(()=>{
      window.location.replace("/login");
    },10000)

    if (serchParams.get("token")) {
      document.cookie = `jwt=${serchParams.get(
        "token"
      )};path=/;domain=${DOMAIN}`;
      window.location.replace("/");
    }

  },[])
  
  return (
    <>
    </>
  );
}

export default NativePage;
